/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-size: 14px;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: hidden;
  background-color: transparent;
  font-family: roboto, sans-serif;
  font-weight: 400;
  font-style: normal;

  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
}

@supports(padding:max(0px)) {
  body, header, footer {
      padding-left: min(0vmin, env(safe-area-inset-left));
      padding-right: min(0vmin, env(safe-area-inset-right));
  }
}

a {
  color: inherit;
}

@screen md {
  .full-height-minus-header {
    max-height: calc(100vh - theme('spacing.24'));
  }
}

.h-but:before {
  /* @apply border-t-4 border-red-400 */
  display:block;
  padding-top: 1.75rem;
  content: '';
  border-top: solid 4px rgba(248, 113, 113, 0);
  transform: scaleX(0);  
  transition: all 80ms ease-in-out;
}
.h-but:hover:before { 
  transform: scaleX(1.3);
  border-top: solid 4px rgba(248, 113, 113, 1);
 }